import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  formButton: {
    "& ": {
      fontFamily: 'Barlow Condensed !important',
      backgroundColor: "#2263a7 !important",
      width: 'fit-content',
      borderRadius: '10px',
      fontSize: '18px',
    },
    "& .MuiButton-label": {
      justifyContent: "left",
      padding: "10px",
      fontWeight: "600 !important",
      backgroundColor: "#2263a7",
      border: "0"
    }
  },
  formInput: {
    "&": {
      marginBottom: "5px"
    },
    "& .MuiFormLabel-filled": {
      color: "#2263a7",
    },
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiInputBase-root ": {
      backgroundColor: "#cae3ff",
      color: "#2263a7",
      outlineWidth: "0",
      width: "350px",
    },
    "& .MuiFilledInput-root": {
      "& input": {
        borderColor: "#cae3ff"
      },
      "&.MuiFilledInput-underline:before": {
        borderBottom: "none"
      },
      "&.MuiFilledInput-underline:after": {
        borderBottom: "2px solid #2263a7"
      }
    },
    "& .MuiInputLabel-animated": {
      color: "#2263a7",
    }
  }
}));

function SuccessSignUp(props) {
  const classes = useStyles();

  const changeURL = (url) => {
    window.location.href = url;
  }

  return (
    <div className="successSignUp" >
      <h5 className="subHeading">Congratulations!</h5>
      <p>You are on your way to managing your {props.env_vars.ui_company_title} account online. You will also receive a confirmation email at the address provided. If you do not see a confirmation email, make sure to check your Junk Mail folder and allow all emails from {props.ui_from_email}</p>
      <Button variant="contained" type="button" color="primary" className={classes.formButton} onClick={(e) => changeURL('/user')}>
        CONTINUE
      </Button>
    </div>
  )
}

export default SuccessSignUp
