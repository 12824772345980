import React from 'react'

function Header(props) {
  console.log("PRINT PROPSSSSSS");
  console.log(props);
  console.log(props.image_logo);
  return (
    <div className="header">
      <a href="/user"><img src={props.image_logo} alt="" /></a>
      <nav className="header__menu">
        {(props.app_name == "solar_landscape") ? (
          <ul className="header__menuItems">
            <a href="/user"><li className="header__menuItem header__menuItemSL">Home</li></a>
            <a href="https://gosolarlandscape.com/#what"><li className="header__menuItem header__menuItemSL">What is Community Solar</li></a>
            <a href="https://gosolarlandscape.com/#about"><li className="header__menuItem header__menuItemSL">About Us</li></a>
            <a href="https://gosolarlandscape.com/faq/"><li className="header__menuItem header__menuItemSL">FAQ</li></a>
            <a href="https://gosolarlandscape.com/in-the-news/"><li className="header__menuItem header__menuItemSL">In the News</li></a>
            <a href="https://solarfarms.formstack.com/forms/navisun_step1?utm_campaign=SCS_Navisun%20Billing&utm_medium=email&utm_source=hs_email"><li className="header__menuItem header__menuItemSL">Sign Up</li></a>
            <li className="header__menuItem header__menuItemSL header__menuItemSelected">Members</li>
          </ul>
        ) : (
          <ul className="header__menuItems">
            <a href="/user"><li className="header__menuItem">HOME</li></a>
            <a href="https://njlocalsolar.com/#what"><li className="header__menuItem">HOW COMMUNITY SOLAR WORKS</li></a>
            <a href="https://njlocalsolar.com/#about"><li className="header__menuItem">ABOUT US</li></a>
            <a href="https://solarfarms.formstack.com/forms/navisun_step1?utm_campaign=SCS_Navisun%20Billing&utm_medium=email&utm_source=hs_email"><li className="header__menuItem">SIGN UP</li></a>
            <a href="https://solarfarms.formstack.com/forms/navisun_contactus"><li className="header__menuItem">CONTACT US</li></a>
            <li className="header__menuItem header__menuItemSelected">MEMBERS</li>
          </ul>
        )}

      </nav>
      {
        props.user &&
        <span className="header__name">
          Welcome: {(props.user.first_name).substring(0, 7)}
        </span>
      }
    </div>
  )
}

export default Header