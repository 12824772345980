// Started POST "/user" for 2409:4070:103:f4cc:3d63:35de:2dc2:58d7 at 2020-12-15 12:35:07 +0530
// Cannot render console from 2409:4070:103:f4cc:3d63:35de:2dc2:58d7! Allowed networks: 2409:4070:2d95:aa46:6cec:d62:fed3:7511, 127.0.0.0/127.255.255.255, ::1
// Processing by User::UsersController#create as HTML
//   Parameters: {"authenticity_token"=>"hB5xqsgQb4cxLtd1jZUGeiNjfeodPeOMDqAelV3kzg/Pv20OoscScZ2gJ89EFOaav3zJZXsLamwrvi2Z4UZkDQ==", "email"=>"", "zipcode"=>"", "g-recaptcha-response"=>"03AGdBq25vEuiEkhpf-v2UO_G1bGLK3HFzNAEak7UalFTSND-M20ZthMjpIkJUhzr-eBZFYG6fYtcl4JRW2gQo0gphprTNnYX47IY19h6oeHRmjEUxzvRvXaBzQZ-6NKjlk2DH516be1rXzx1KNBNUByoQBMkM0D8XnE_RLwQl6NlhIDvsd5ugC6n_yNHvjW9an3jV6f4BReA_eqUgkviY2j7MhA1hpy4asptTmUSgjsOnbOCEcuoFXqDc_xQy3MT17jrTtTRz4XuMbTrhowII1Oy2xuUcJdaGu6yLiOk-YAP3z13CrZswKzkNiY0EjO7yozdjuS1iWhHx_mnR7r17dIjxsX2Z5bHdj-z4aIkiBnekh3a7xqSwmJ5ykKmGjmrXReBsmhDUW-oVcvt_pIO90Sam9OAK5NBEiO_DBkQgRCquu3Cp0yUDZshdgeyUZENHMkCMpW3b4I1_EjzFQl3_FYw8XL1_5Gv5vIO5WOdC3NSCj9eBqDjlIqkn9TiwSLlzVHKbv2yeRqCOfCV5a3c5TvwoghAC9mF5EA"}
import React, { useState } from 'react'
import Session from './templates/Session'
import FormBanner from './shared/FormBanner'
import FormSignUp from './forms/FormSignUp'
import SuccessSignUp from './forms/SuccessSignUp'

const HeadingMessage = () =>{
  return(
    <>
      <h1 className="mainHeading">New Account Set Up</h1>
    </>
  )
}

function SignUp(props) {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    console.warn("CLICKED SUBMIT FORM ");
    setIsSubmitted(true);
  }

  const signUpForm = () => {
    return (
      <>
        <Session form={<FormSignUp submitForm={()=>{console.warn("CLICKED SUBMIT FORM "); setIsSubmitted(true);}} {...props}/>} formBanner = {<a href="https://solarfarms.formstack.com/forms/navisun_step1"><FormBanner {...props} /></a>} heading={<HeadingMessage />} {...props}/>
      </>
    )
  }

  const signUpSuccess = () => {
    return (
      <>
        <Session form={<SuccessSignUp {...props}/>} 
          heading={<HeadingMessage />} {...props}/>
      </>
    )
  }
  return (
    <div className="signUp">
      {/* {isSubmitted ?  signUpSuccess() : signUpForm() } */}
      {isSubmitted ?  signUpSuccess() : signUpForm()}
    </div>
  )
}

export default SignUp
